import React from "react";
import "./RoadPackage.scss";

import { FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import RoadPackageItem from "./RoadPackageItem/RoadPackageItem";
import CreateOrderProductType from "./../../Components/CreateOrderProductType/CreateOrderProductType";
import DInputs from "./../../../../Form/DInputs/DInputs";
import CreateOrderAdrSection from "./../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderSectionSecondaryTitle from "./../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import RoadWeightGroup from "./RoadPackageItem/RoadWeightGroup/RoadWeightGroup";
import DButton from "./../../../../Form/DButtons/DButton/DButton";
import CreateOrderOversizedSection from "../../Components/CreateOrderOversizedSection/CreateOrderOversizedSection";

const RoadPackage = ({
  edit,
  //////////
  totalWeight,
  setTotalWeight,
  totalWeightError,
  /////////
  cargoType,
  setCargoType,
  cargoTypeError,
  HSCode,
  setHSCode,
  HSCodeValue,
  setHSCodeValue,
  HSCodeValueError,
  ///////
  adr,
  setAdr,
  adrError,
  adrNumber,
  setAdrNumber,
  adrNumberError,
  adrClass,
  setAdrClass,
  adrClassError,
  adrFile,
  setAdrFile,
  ///////
  oversized,
  setOversized,
  oversizedError,
  oversizedFile,
  setOversizedFile,
  oversizedCall,
  setOversizedCall,
  /////
  group,
  setGroup,
}) => {
  const { t } = useTranslation();

  const filterUniqueAndSort = (array) => {
    const uniqueSet = new Set();
    const filteredArray = array.filter((item) => {
      if (!uniqueSet.has(item.unique)) {
        uniqueSet.add(item.unique);
        return true;
      }
      return false;
    });
    return filteredArray.sort((a, b) => a.unique - b.unique);
  };
  const uniqueGroup = filterUniqueAndSort(group);
  const createNewItemHandler = () => {
    const newGroup = [
      ...group,
      {
        unique: group[group.length - 1].unique + 1,
        trailerType: null,
        trailerTypeError: null,
        trailerSize: null,
        trailerSizeError: null,
        temperature: "",
        temperatureError: null,
        weight: "",
        weightError: null,
      },
    ];
    setGroup(newGroup);
  };

  return (
    <div id='ShipperRoadPackage'>
      <CreateOrderSectionTitle title={t("shipper.forms.titles.Order requirements")} />
      <div className='ShipperRoadPackageContainer'>
        <div className='productWrapper'>
          <div className='productWrapperProduct'>
            <CreateOrderProductType
              cargoType={cargoType}
              setCargoType={setCargoType}
              cargoTypeError={cargoTypeError}
              HSCode={HSCode}
              setHSCode={setHSCode}
              HSCodeValue={HSCodeValue}
              setHSCodeValue={setHSCodeValue}
              HSCodeValueError={HSCodeValueError}
            />
          </div>
          <div className='TotalproductWrapperWeight'>
            <DInputs
              required
              type={"number"}
              id={`ShipperAirPackageItemTotalWeight`}
              placeholder={t("shipper.forms.content.Enter weight")}
              label={
                edit
                  ? t("shipper.forms.content.Weight (KG)")
                  : t("shipper.forms.content.Total weight (KG)")
              }
              value={totalWeight}
              setValue={setTotalWeight}
              error={totalWeightError}
            />
          </div>
        </div>

        <CreateOrderAdrSection
          edit={edit}
          adr={adr}
          setAdr={setAdr}
          adrError={adrError}
          adrNumber={adrNumber}
          setAdrNumber={setAdrNumber}
          adrNumberError={adrNumberError}
          adrClass={adrClass}
          setAdrClass={setAdrClass}
          adrClassError={adrClassError}
          adrFile={adrFile}
          setAdrFile={setAdrFile}
        />

        <div className='roadOversizedContainer'>
          <CreateOrderOversizedSection
            edit={edit}
            oversized={oversized}
            setOversized={setOversized}
            oversizedError={oversizedError}
            oversizedFile={oversizedFile}
            setOversizedFile={setOversizedFile}
            oversizedCall={oversizedCall}
            setOversizedCall={setOversizedCall}
          />
        </div>

        <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Trailer requirements")} />

        <div className='roadTrailersContainer'>
          {uniqueGroup.map((item) => (
            <RoadPackageItem
              edit={edit}
              key={item.unique}
              index={item.unique}
              item={item}
              group={group}
              setGroup={setGroup}
              uniqueGroup={uniqueGroup}
            />
          ))}
        </div>

        {!edit && (
          <div className='createNewOrderBtn'>
            <DButton
              title={t("shipper.buttons.Add new Trailer type")}
              leftIcon={<FiPlusCircle />}
              onClick={() => createNewItemHandler()}
            />
          </div>
        )}

        {!edit && group.length > 1 && (
          <RoadWeightGroup totalWeight={totalWeight} group={group} setGroup={setGroup} />
        )}
      </div>
    </div>
  );
};

export default RoadPackage;
