import React, { useState, Fragment } from "react";
import "./LoadsTableItem.scss";

import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";

import RowField from "../../../Form/TableFields/RowField/RowField";
import TextField from "../../../Form/TableFields/TextField/TextField";
import TrailerField from "../../../Form/TableFields/TrailerField/TrailerField";
import DateField from "./../../../Form/TableFields/DateField/DateField";
import LocationFieldLoadBoards from "../../../Form/TableFields/LocationFieldLoadBoards/LocationFieldLoadBoards";
import MakeBidModal from "./../../Modals/MakeBidModal/MakeBidModal";
import CenterField from "../../../Form/TableFields/CenterField/CenterField";
import PriceField from "./../../../Form/TableFields/PriceField/PriceField";
import DButton from "../../../Form/DButtons/DButton/DButton";
import TakeOrderModal from "./../../Modals/TakeOrderModal/TakeOrderModal";

const LoadsTableItem = ({ item }) => {
  const { t } = useTranslation();
  const [openBidModal, setOpenBidModal] = useState(false);
  const [showTakeOrderButton, setShowTakeOrderButton] = useState(false);

  return (
    <Fragment>
      <RowField>
        <LocationFieldLoadBoards item={item} />
        <DateField date={item?.cargos[0]?.pickup_date || null} />
        <TextField label={item?.weight ? item?.weight : "-"} />
        <TrailerField
          icon={item?.cargos[0]?.trailerType?.icon}
          trailerType={item?.cargos[0]?.trailerType?.name || "-"}
          volume={item?.cargos[0]?.trailersize?.label || "-"}
        />
        {item.bid ? (
          <PriceField
            icon={item?.takeable && <FaCheckCircle />}
            color={!item?.takeable && "#2E5AAC"}
            number={item?.bid?.amount || 0}
            symbol={item?.bid?.currency?.symbol ?? ""}
          />
        ) : (
          <CenterField>
            <div id='BidePriceFieldLoadBoard'>
              <p className='price'>{t("tms.tables.noInfo.No bid")}</p>
            </div>
          </CenterField>
        )}
        <CenterField>
          {item?.takeable ? (
            <div id='AllLoadsTakeOrder'>
              <DButton
                title={t("tms.tables.buttons.Take order")}
                onClick={() => setShowTakeOrderButton(true)}
              />
            </div>
          ) : (
            <div id='AllLoadsMakeBid'>
              {item.bid ? (
                <DButton
                  title={t("tms.tables.buttons.New bid")}
                  onClick={() => setOpenBidModal(true)}
                />
              ) : (
                <DButton
                  title={t("tms.tables.buttons.Make bid")}
                  onClick={() => setOpenBidModal(true)}
                />
              )}
            </div>
          )}
        </CenterField>
      </RowField>
      {showTakeOrderButton && (
        <TakeOrderModal
          item={item}
          isOpen={showTakeOrderButton}
          isClose={() => setShowTakeOrderButton(false)}
        />
      )}
      {openBidModal && (
        <MakeBidModal item={item} isOpen={openBidModal} isClose={() => setOpenBidModal(false)} />
      )}
    </Fragment>
  );
};

export default LoadsTableItem;
