import React, { Fragment, useState } from "react";
import "./TrackingHeader.scss";

import { useTranslation } from "react-i18next";
import { MdHistory } from "react-icons/md";
import { SlMap } from "react-icons/sl";

import DButton from "../../../Form/DButtons/DButton/DButton";
// import TrackingShipperMapModal from "../TrackingShipperMapModal/TrackingShipperMapModal";
import DirectionShipperMapModal from "../TrackingShipperMapModal/DirectionShipperMapModal";

const TrackingHeader = ({ order }) => {
  const { t } = useTranslation();
  const [openMap, setOpenMap] = useState(false);
  return (
    <Fragment>
      <div id='ShipperTrackingSectionHeader'>
        <div className='BlockA'>
          <div className='icon'>
            <MdHistory />
          </div>
          <h2>{t("shipper.titles.Shipment tracking")}</h2>
        </div>
        <div className='BlockB'>
          <DButton
            title={t("shipper.buttons.Show on map")}
            rightIcon={<SlMap />}
            onClick={() => setOpenMap(true)}
          />
        </div>
      </div>
      {openMap && (
        // <TrackingShipperMapModal order={order} isOpen={openMap} isClose={() => setOpenMap(false)} />
        <DirectionShipperMapModal
          order={order}
          isOpen={openMap}
          isClose={() => setOpenMap(false)}
        />
      )}
    </Fragment>
  );
};

export default TrackingHeader;
