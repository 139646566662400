import React, { useState, useEffect } from "react";
import "./SignUpShipperAuthForm.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { signupStep2, resetSignupStep2 } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import TermsAgree from "../../../Form/Checkbox/TermsAgree/TermsAgree";

const SignUpShipperAuthForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { signupStep2Loading, signupStep2Result, signupStep2Error } = useSelector(
    (state) => state.auth
  );

  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const countryId = searchParams.get("countryId");
  const phoneIndexId = searchParams.get("phoneIndex");
  const phone = searchParams.get("phone");
  const [companyVat, setCompanyVat] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(true);

  useEffect(() => {
    if (signupStep2Result) {
      const url = `/register/shipper/verify?firstName=${firstName}&lastName=${lastName}&countryId=${countryId}&phoneIndexId=${phoneIndexId}&phone=${phone}&companyVat=${companyVat}&companyName=${companyName}&email=${email}&agree=${agree}`;
      dispatch(resetSignupStep2());
      navigate(url);
    }
  }, [
    navigate,
    dispatch,
    signupStep2Result,
    firstName,
    lastName,
    countryId,
    phoneIndexId,
    phone,
    companyVat,
    companyName,
    email,
    agree,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSignupStep2());
    };
  }, [dispatch]);

  const submitHandler = () => {
    let data = {
      branch_id: countryId,
      type_id: 3,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_index_id: phoneIndexId,
      phone: phone,
      password: password,
      password_confirmation: password,
      company: {
        legal_id: companyVat,
        name: companyName,
      },
      agreement: agree,
    };
    dispatch(signupStep2(data));
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperAuthForm' className='SignUpShipperAuthForm'>
        <FormTitle title={t("landing.auth.titles.Welcome to the future of logistics")}>
          <span>{t("landing.auth.titles.We almost there")}</span>
        </FormTitle>
        <ProgressBar position={2} />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Company VAT number")} id='CompanyVATnumber' />
            <Input
              value={companyVat}
              setValue={setCompanyVat}
              placeholder={t("landing.auth.form.Enter company VAT number")}
              id='CompanyVATnumber'
              error={signupStep2Error?.errors?.["company.legal_id"]?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.["company.legal_id"]?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Company name")} id='CompanyName' />
            <Input
              value={companyName}
              setValue={setCompanyName}
              placeholder={t("landing.auth.form.Enter company name")}
              id='CompanyName'
              error={signupStep2Error?.errors?.["company.name"]?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.["company.name"]?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Email")} id='AuthEmail' />
            <Input
              value={email}
              setValue={setEmail}
              placeholder={t("landing.auth.form.Enter email")}
              id='AuthEmail'
              error={signupStep2Error?.errors?.email?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.email?.[0] ?? null} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Create Password")}
              id='Password'
              warning
              warningText={t(
                "landing.form.warningText.password must contain minimum of 6 character"
              )}
            />
            <Input
              value={password}
              setValue={setPassword}
              placeholder={t("landing.auth.form.Enter password")}
              id='Password'
              password
              error={signupStep2Error?.errors?.password?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.password?.[0] ?? null} />
          </div>
          <div>
            <TermsAgree value={agree} setValue={setAgree} />
            <ErrorText error={signupStep2Error?.errors?.agreement?.[0] ?? null} />
          </div>
        </div>

        <Button
          loading={signupStep2Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperAuthForm;
