import _request from "./../_request";

const updateUser = async (ISO, token, data) => {
  const config = {
    url: "/profile",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const updateUserCompanyLogo = async (ISO, token, data) => {
  const config = {
    url: "/user/profile",
    method: "put",
    ISO,
    token,
    file: true,
  };
  return _request(config, data);
};

const updateUserLanguage = async (ISO, token, data) => {
  const config = {
    url: "/user/profile",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const verify = async (ISO, token, data) => {
  const config = {
    url: "/verify",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const changePassword = async (ISO, token, data) => {
  const config = {
    url: "/profile/change-password",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const userService = {
  updateUser,
  updateUserCompanyLogo,
  updateUserLanguage,
  verify,
  changePassword,
};

export default userService;
