import React, { useState, useEffect, Fragment } from "react";
import "./EditRoadOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import moment from "moment";

import {
  getOrder,
  resetGetOrder,
  updateOrder,
  resetUpdateOrder,
} from "../../../../../../store/orders/ordersSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import CreateOrderHeader from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import RoadTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTypeSwitcher/RoadTypeSwitcher";
import RoadTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTopMenu/RoadTopMenu";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import RoadLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadLocation/RoadLocation";
import RoadPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadPackage/RoadPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const EditRoadOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    getOrderLoading,
    getOrderResult,
    getOrderError,
    updateOrderLoading,
    updateOrderResult,
    updateOrderError,
  } = useSelector((state) => state.orders);

  const ORDERTYPES = [
    { id: 2, name: "FTL" },
    { id: 1, name: "LTL" },
  ];

  const [orderTypeId, setOrderTypeId] = useState(2);
  //// Route
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      error: null,
    },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [showPickUpTime, setShowPickUpTime] = useState(false);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [totalWeight, setTotalWeight] = useState("");
  const [cargoType, setCargoType] = useState(null);
  const [HSCode, setHSCode] = useState(false);
  const [HSCodeValue, setHSCodeValue] = useState("");
  const [adr, setAdr] = useState(false);
  const [adrNumber, setAdrNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [oversized, setOversized] = useState(false);
  const [group, setGroup] = useState([
    {
      unique: 1,
      trailerType: null,
      trailerTypeError: null,
      trailerSize: null,
      trailerSizeError: null,
      temperature: "",
      temperatureError: null,
      weight: "",
      weightError: null,
    },
  ]);
  ///// Additional Services
  const [selectedServices, setSelectedServices] = useState([]);
  ///// Additional information
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          label: user?.currency?.code || "",
          value: user?.currency || null,
        }
      : null
  );
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [comment, setComment] = useState("");

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // fill order data
  useEffect(() => {
    if (getOrderResult) {
      setOrderTypeId(getOrderResult?.type?.id || 2);
      setLocations(
        getOrderResult?.cargos?.[0]?.locations
          ? getOrderResult?.cargos?.[0]?.locations.map((i) => ({
              id: i.id,
              direction: i.direction,
              inputted_address: i.inputted_address,
              error: null,
            }))
          : [
              { direction: "A", inputted_address: "", error: null },
              { direction: "B", inputted_address: "", error: null },
            ]
      );
      setPickUpDate(
        getOrderResult?.cargos?.[0]?.pickup_date
          ? new Date(getOrderResult?.cargos?.[0]?.pickup_date)
          : null
      );
      setShowPickUpTime(
        getOrderResult?.cargos[0]?.pickup_time_start && getOrderResult?.cargos[0]?.pickup_time_end
          ? true
          : false
      );
      setPickUpTime(
        getOrderResult?.cargos[0]?.pickup_time_start && getOrderResult?.cargos[0]?.pickup_time_end
          ? [
              getOrderResult?.cargos[0]?.pickup_time_start,
              getOrderResult?.cargos[0]?.pickup_time_end,
            ]
          : null
      );
      setCargoType(
        getOrderResult?.cargos?.[0]?.type
          ? {
              label: getOrderResult?.cargos?.[0]?.type?.name,
              value: getOrderResult?.cargos?.[0]?.type,
            }
          : null
      );
      setHSCode(getOrderResult?.cargos?.[0]?.hs_code ? true : false);
      setHSCodeValue(getOrderResult?.cargos?.[0]?.hs_code || "");
      setAdr(getOrderResult?.cargos?.[0]?.adr ? true : false);
      setAdrNumber(getOrderResult?.cargos?.[0]?.un_number || "");
      setAdrClass(getOrderResult?.cargos?.[0]?.adr_class || "");
      setOversized(getOrderResult?.cargos?.[0]?.oversized ? true : false);
      setTotalWeight(getOrderResult?.cargos[0]?.weight || "");
      setGroup([
        {
          unique: getOrderResult?.cargos[0]?.id || null,
          trailerType: getOrderResult?.cargos[0]?.trailerType
            ? {
                label: getOrderResult?.cargos[0]?.trailerType?.name || "",
                value: getOrderResult?.cargos[0]?.trailerType || null,
              }
            : null,
          trailerTypeError: null,
          trailerSize: getOrderResult?.cargos[0]?.trailersize
            ? {
                label: getOrderResult?.cargos[0]?.trailersize?.label || "",
                value: getOrderResult?.cargos[0]?.trailersize || null,
              }
            : null,
          trailerSizeError: null,
          temperature: getOrderResult?.cargos[0]?.temperature || "",
          temperatureError: null,
          weight: getOrderResult?.cargos[0]?.weight || "",
          weightError: null,
        },
      ]);
      setSelectedServices(getOrderResult?.services || []);
      setCargoPrice(getOrderResult?.cargos[0]?.price || "");
      setCargoPriceCurrency(
        getOrderResult?.cargos[0]?.price_currency
          ? {
              label: getOrderResult?.cargos[0]?.price_currency?.code,
              value: getOrderResult?.cargos[0]?.price_currency,
            }
          : {
              label: user?.currency?.code || "",
              value: user?.currency || null,
            }
      );
      setReadyCargoTime(
        getOrderResult?.cargos?.[0]?.cargo_ready_date
          ? new Date(getOrderResult?.cargos?.[0]?.cargo_ready_date)
          : null
      );
      setComment(getOrderResult?.about || "");
    }
  }, [getOrderResult, user]);

  // catch locations error
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedLocations = locations.map((location, i) => ({
        ...location,
        error: updateOrderError.errors[`cargos.0.locations.${i}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  // catch group errors
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        trailerTypeError: updateOrderError?.errors?.[`cargos.0.trailer_type_id`]?.[0] || null,
        trailerSizeError: updateOrderError?.errors?.[`cargos.0.trailer_size_id`]?.[0] || null,
        temperatureError: updateOrderError?.errors?.[`cargos.0.temperature`]?.[0] || null,
        weightError: updateOrderError?.errors?.[`cargos.0.weight`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  // reset
  useEffect(() => {
    if (updateOrderResult) {
      dispatch(resetUpdateOrder());
      navigate(`/dashboard/shipper/orders/${orderId}`);
    }
  }, [dispatch, navigate, orderId, updateOrderResult]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrder());
    };
  }, [dispatch]);

  // submit
  const submitHandler = () => {
    const data = {
      orderId: orderId,
      priority_id: 1,
      transfer_type_id: 1,
      type_id: orderTypeId || null,
      revenue: getOrderResult?.revenue || 0,
      revenue_currency_id: getOrderResult?.revenue_currency?.id || null,
      client_payment: null,
      about: comment || null,
      services: selectedServices.map((i) => i.id),
      cargos: [
        {
          id: group[0].unique,
          trailer_type_id: group[0]?.trailerType?.value?.id || null,
          trailer_size_id: group[0]?.trailerSize?.value?.id || null,
          temperature: group[0]?.temperature || null,
          type_id: cargoType?.value?.id || null,
          count: 1,
          weight: totalWeight || null,
          volume: null,
          adr: adr || false,
          adr_class: adrClass || null,
          un_number: adrNumber || null,
          hs_code: HSCodeValue || null,
          oversized: oversized || false,
          price: cargoPrice || null,
          price_currency_id: cargoPriceCurrency?.value?.id || null,
          cargo_ready_date: readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : null,
          pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : null,
          pickup_time_start: pickUpTime ? pickUpTime[0] : null,
          pickup_time_end: pickUpTime ? pickUpTime[1] : null,
          dropoff_date: null,
          dropoff_time_start: null,
          dropoff_time_end: null,
          locations: locations.map((loc) => ({
            id: loc?.id || null,
            direction: loc?.direction || "A",
            type: loc?.type || "LOCALITY",
            inputted_address: loc?.inputted_address || "",
          })),
        },
      ],
    };
    dispatch(updateOrder(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Road freight")}>
      {getOrderLoading && <LoadingPage />}
      {getOrderError && <PageNotFound />}
      {getOrderResult && (
        <Fragment>
          <RoadTopMenu
            ORDERTYPES={ORDERTYPES}
            orderTypeId={orderTypeId}
            pickUpDate={pickUpDate}
            totalWeight={totalWeight}
            cargoType={cargoType}
            group={group}
            locations={locations}
          />
          <section id='ShipperEditOrderRoad'>
            <div className='ShipperCreateOrderRoadWrapper'>
              <CreateOrderHeader />
              <RoadTypeSwitcher
                ORDERTYPES={ORDERTYPES}
                orderTypeId={orderTypeId}
                setOrderTypeId={setOrderTypeId}
              />
              <RoadLocation
                locations={locations}
                setLocations={setLocations}
                pickUpDate={pickUpDate}
                setPickUpDate={setPickUpDate}
                pickUpDateError={updateOrderError?.errors?.["cargos.0.pickup_date"]?.[0] ?? null}
                showPickUpTime={showPickUpTime}
                setShowPickUpTime={setShowPickUpTime}
                pickUpTime={pickUpTime}
                setPickUpTime={setPickUpTime}
                pickUpTimeError={
                  (updateOrderError?.errors?.["cargos.0.pickup_time_start"]?.[0] ?? null) ||
                  (updateOrderError?.errors?.["cargos.0.pickup_time_end"]?.[0] ?? null)
                }
              />
              <RoadPackage
                edit={true}
                totalWeight={totalWeight}
                setTotalWeight={setTotalWeight}
                totalWeightError={updateOrderError?.errors?.[`cargos.0.weight`]?.[0] || null}
                //////
                cargoType={cargoType}
                setCargoType={setCargoType}
                cargoTypeError={updateOrderError?.errors?.["cargos.0.type_id"]?.[0] ?? null}
                HSCode={HSCode}
                setHSCode={setHSCode}
                HSCodeValue={HSCodeValue}
                setHSCodeValue={setHSCodeValue}
                HSCodeValueError={updateOrderError?.errors?.["cargos.0.hs_code"]?.[0] ?? null}
                //////
                adr={adr}
                setAdr={setAdr}
                adrError={updateOrderError?.errors?.["cargos.0.adr"]?.[0] ?? null}
                adrNumber={adrNumber}
                setAdrNumber={setAdrNumber}
                adrNumberError={updateOrderError?.errors?.["cargos.0.un_number"]?.[0] ?? null}
                adrClass={adrClass}
                setAdrClass={setAdrClass}
                adrClassError={updateOrderError?.errors?.["cargos.0.adr_class"]?.[0] ?? null}
                //////
                oversized={oversized}
                setOversized={setOversized}
                oversizedError={updateOrderError?.errors?.["cargos.0.oversized"]?.[0] ?? null}
                //////
                group={group}
                setGroup={setGroup}
              />
              <CreateOrderAditionalServices
                title={t("shipper.forms.titles.Additional Services")}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <CreateOrderAditionalInformation
                edit={true}
                title={t("shipper.forms.titles.Additional information")}
                /////
                readyCargoTime={readyCargoTime}
                setReadyCargoTime={setReadyCargoTime}
                readyCargoTimeError={
                  updateOrderError?.errors?.["cargos.0.cargo_ready_date"]?.[0] ?? null
                }
                /////
                cargoPrice={cargoPrice}
                setCargoPrice={setCargoPrice}
                cargoPriceError={updateOrderError?.errors?.["cargos.0.price"]?.[0] ?? null}
                /////
                cargoPriceCurrency={cargoPriceCurrency}
                setCargoPriceCurrency={setCargoPriceCurrency}
                cargoPriceCurrencyError={
                  updateOrderError?.errors?.["cargos.0.price_currency_id"]?.[0] ?? null
                }
                //////
                comment={comment}
                setComment={setComment}
                commentError={updateOrderError?.errors?.["about"]?.[0] ?? null}
              />

              <CreateOrderSubmit
                icon={<FiCheck />}
                title={t("shipper.buttons.Save")}
                loading={updateOrderLoading}
                onClick={() => submitHandler()}
              />
            </div>
          </section>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditRoadOrder;
