import React, { Fragment, useState, useEffect } from "react";
import "./LanguageSidebar.scss";

import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { setLang } from "../../../../store/additional/lang/langSlice";

import DModal from "../../../Modal/DModal/DModal";
import {
  resetUpdateUserLanguage,
  updateUserLanguage,
} from "../../../../store/user/userSlice";

const LanguageSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openSidebar } = useSelector((state) => state.userUI);
  const { getLanguagesResult, ISO } = useSelector((state) => state.lang);
  const { updateUserLanguageResult, updateUserLanguageError } = useSelector(
    (state) => state.user
  );

  const [openLangModal, setOpenLangModal] = useState(false);
  const [currentLang, setCurrentLang] = useState(null);

  useEffect(() => {
    const findValue = getLanguagesResult && getLanguagesResult.find((item) => item.key === ISO);
    setCurrentLang(findValue);
  }, [ISO, getLanguagesResult, openLangModal]);

  const submitHandler = () => {
    dispatch(updateUserLanguage({ language: currentLang.key }));
    dispatch(setLang(currentLang.key));
  };

  useEffect(() => {
    if (updateUserLanguageResult) {
      dispatch(resetUpdateUserLanguage());
    }
  }, [currentLang, dispatch, updateUserLanguageResult]);

  const RenderItem = ({ item }) => {
    return (
      <div
        onClick={() => setCurrentLang(item)}
        id='LanguageSidebarItem'
        className={item === currentLang ? "active" : ""}
      >
        <div
          className='flag'
          style={{
            backgroundImage: `url(${item?.flag})`,
          }}
        ></div>
        <p>{item.native}</p>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        id='LanguageSidebar'
        onClick={() => setOpenLangModal(true)}
        className={openSidebar ? "" : "min"}
      >
        <div className='container'>
          <div
            className='flag'
            style={
              getLanguagesResult && {
                backgroundImage: `url(${
                  getLanguagesResult.find((item) => item.key === ISO)?.flag
                })`,
              }
            }
          ></div>
          <div className={openSidebar ? "text" : "text min"}>
            {getLanguagesResult && getLanguagesResult.find((item) => item.key === ISO)?.native}
          </div>
        </div>

        <div className='arrow'>
          <FiChevronRight />
        </div>
      </div>
      <DModal
        isOpen={openLangModal}
        isClose={() => setOpenLangModal(false)}
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Change language")}
        icon={<MdOutlineLanguage />}
        loading={updateUserLanguageError}
      >
        <div id='LanguageSidebarModal'>
          {getLanguagesResult && (
            <div className='LanguageSidebarModalWrapper'>
              {getLanguagesResult.map((item) => (
                <RenderItem item={item} key={item.key} />
              ))}
            </div>
          )}
        </div>
      </DModal>
    </Fragment>
  );
};

export default LanguageSidebar;
