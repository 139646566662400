import React from "react";
import "./OceanPackageItem.scss";

import { useTranslation } from "react-i18next";

import CreateOrderAdrSection from "../../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderProductType from "../../../Components/CreateOrderProductType/CreateOrderProductType";
import CreateOrderSectionSecondaryTitle from "./../../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import CreateOrderContainerGroup from "./CreateOrderContainerGroup/CreateOrderContainerGroup";
import CreateOrderOversizedSection from "../../../Components/CreateOrderOversizedSection/CreateOrderOversizedSection";
import CreateOrderRemoveItemBtn from "./../../../Components/CreateOrderRemoveItemBtn/CreateOrderRemoveItemBtn";

const OceanPackageItem = ({ edit, group, setGroup, index, item }) => {
  const { t } = useTranslation();

  const cargoTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].cargoType = value;
    setGroup(updatedGroup);
  };
  const HSCodeHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCode = !item.HSCode;
    updatedGroup[index].HSCodeValue = "";
    setGroup(updatedGroup);
  };
  const HSCodeValueHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCodeValue = value;
    setGroup(updatedGroup);
  };
  const adrHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].adr = !item.adr;
    updatedGroup[index].adrNumber = "";
    updatedGroup[index].adrClass = "";
    updatedGroup[index].adrFile = [];
    setGroup(updatedGroup);
  };
  const adrNumberHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrNumber = value;
    setGroup(updatedGroup);
  };
  const adrClassHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrClass = value;
    setGroup(updatedGroup);
  };
  const adrFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrFile = value;
    setGroup(updatedGroup);
  };
  const oversizedHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].oversized = !item.oversized;
    updatedGroup[index].oversizedFile = [];
    updatedGroup[index].oversizedCall = false;
    setGroup(updatedGroup);
  };
  const oversizedFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].oversizedFile = value;
    setGroup(updatedGroup);
  };
  const oversizedCallHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].oversizedCall = value;
    setGroup(updatedGroup);
  };
  const removeItemHandler = () => {
    const updatedGroup = [...group];
    updatedGroup.splice(index, 1);
    setGroup(updatedGroup);
  };

  return (
    <div id='OceanPackageItem'>
      <CreateOrderRemoveItemBtn
        show={group.length > 1 ? true : false}
        onClick={removeItemHandler}
      />

      <div className='productWrapper'>
        <CreateOrderProductType
          cargoType={item.cargoType}
          setCargoType={(value) => cargoTypeHandler(value)}
          cargoTypeError={item.cargoTypeError}
          HSCode={item.HSCode}
          setHSCode={() => HSCodeHandler()}
          HSCodeValue={item.HSCodeValue}
          setHSCodeValue={(value) => HSCodeValueHandler(value)}
          HSCodeValueError={item.HSCodeValueError}
        />
      </div>
      <div className='productAdrWrapper'>
        <CreateOrderAdrSection
          edit={edit}
          adr={item.adr}
          setAdr={(value) => adrHandler(value)}
          adrError={item.adrError}
          adrNumber={item.adrNumber}
          setAdrNumber={(value) => adrNumberHandler(value)}
          adrNumberError={item.adrNumberError}
          adrClass={item.adrClass}
          setAdrClass={(value) => adrClassHandler(value)}
          adrClassError={item.adrClassError}
          adrFile={item.adrFile}
          setAdrFile={(value) => adrFileHandler(value)}
        />
      </div>
      <div className='productOversizedWrapper'>
        <CreateOrderOversizedSection
          edit={edit}
          oversized={item.oversized}
          setOversized={(value) => oversizedHandler(value)}
          oversizedError={item.oversizedError}
          oversizedFile={item.oversizedFile}
          setOversizedFile={(value) => oversizedFileHandler(value)}
          oversizedCall={item.oversizedCall}
          setOversizedCall={(value) => oversizedCallHandler(value)}
        />
      </div>

      <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Container requirements")} />
      <CreateOrderContainerGroup
        edit={edit}
        index={index}
        item={item}
        group={group}
        setGroup={setGroup}
      />
    </div>
  );
};

export default OceanPackageItem;
