import React, { Fragment, useState } from "react";
import "./ProductNewOrder.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DCargoTypesSelect from "../../../../Form/DSelect/DCargoTypesSelect/DCargoTypesSelect";
import DCustomersSelect from "../../../../Form/DSelect/DCustomersSelect/DCustomersSelect";
import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";
import DInputs from "../../../../Form/DInputs/DInputs";
import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";
import ErrorText from "../../../../../componentsLanding/Form/ErrorText/ErrorText";
import CreateCustomerModal from "../../../../CustomersPartners/Customers/CreateCustomerModal/CreateCustomerModal";

const ProductNewOrder = ({
  customer,
  setCustomer,
  customerError,
  /////
  productType,
  setProductType,
  productTypeError,
  /////
  adr,
  setAdr,
  adrError,
  ////
  adrUnNumber,
  setAdrUnNumber,
  adrUnNumberError,
  ////
  adrClass,
  setAdrClass,
  adrClassError,

  ////
  hideCustomerSelect,
}) => {
  const { t } = useTranslation();

  const [showAddCutomer, setShowAddCutomer] = useState(false);

  return (
    <Fragment>
      <div id='ProductNewOrder'>
        <NewOrderTitle title={t("tms.titles.Product")} />
        <div className='selectsection'>
          {!hideCustomerSelect && (
            <div className='groupItem'>
              <DCustomersSelect value={customer} setValue={setCustomer} error={customerError} />
              <div className='actionButton'>
                <DTextButton
                  onClick={() => setShowAddCutomer((state) => !state)}
                  primary
                  title={t("tms.forms.buttons.Create customer")}
                  leftIcon={<FiPlus />}
                />
              </div>
            </div>
          )}

          <div className='groupItem'>
            <DCargoTypesSelect
              required
              value={productType}
              setValue={setProductType}
              error={productTypeError}
            />
          </div>
        </div>
        <div className='checkboxsection'>
          <div className='adrWrapper'>
            <DCheckBox
              value={adr}
              setValue={() => {
                setAdr((state) => (state === true ? false : true));
              }}
              label={t("tms.forms.labels.Is your product ADR?")}
            />
            <ErrorText error={adrError} />
            {(adr || adrClassError || adrUnNumberError) && (
              <div className='adrContainer'>
                <DInputs
                  id='adrUnNumber'
                  value={adrUnNumber}
                  setValue={setAdrUnNumber}
                  label={t("tms.forms.labels.UN number")}
                  placeholder={t("tms.forms.placeholder.UN number")}
                  error={adrUnNumberError}
                />
                <DInputs
                  id='adrClass'
                  value={adrClass}
                  setValue={setAdrClass}
                  label={t("tms.forms.labels.ADR class")}
                  placeholder={t("tms.forms.placeholder.ADR class")}
                  error={adrClassError}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showAddCutomer && (
        <CreateCustomerModal isOpen={showAddCutomer} isClose={() => setShowAddCutomer(false)} />
      )}
    </Fragment>
  );
};

export default ProductNewOrder;
