import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
  updateUserLoading: false,
  updateUserResult: null,
  updateUserError: null,

  updateUserLanguageLoading: false,
  updateUserLanguageResult: null,
  updateUserLanguageError: null,

  verifyLoading: false,
  verifyResult: null,
  verifyError: null,

  changePasswordLoading: false,
  changePasswordResult: null,
  changePasswordError: null,

  updateUserCompanyLogoLoading: false,
  updateUserCompanyLogoResponce: null,
  updateUserCompanyLogoError: null,
};

export const updateUser = createAsyncThunk("user/updateUser", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await userService.updateUser(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUserCompanyLogo = createAsyncThunk(
  "user/updateUserCompanyLogo",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const formData = new FormData();
      formData.append("document", data);
      const result = await userService.updateUserCompanyLogo(ISO, TOKEN, formData);
      return result;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserLanguage = createAsyncThunk(
  "user/updateUserLanguage",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userService.updateUserLanguage(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verify = createAsyncThunk("user/verify", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await userService.verify(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const changePassword = createAsyncThunk("user/changePassword", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await userService.changePassword(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUpdateUser: (state) => {
      state.updateUserLoading = false;
      state.updateUserResult = null;
      state.updateUserError = null;
    },
    resetUpdateUserCompanyLogo: (state) => {
      state.updateUserCompanyLogoLoading = false;
      state.updateUserCompanyLogoResponce = null;
      state.updateUserCompanyLogoError = null;
    },
    resetUpdateUserLanguage: (state) => {
      state.updateUserLanguageLoading = false;
      state.updateUserLanguageResult = null;
      state.updateUserLanguageError = null;
    },
    resetVerify: (state) => {
      state.verifyLoading = false;
      state.verifyResult = null;
      state.verifyError = null;
    },
    resetChangePassword: (state) => {
      state.changePasswordLoading = false;
      state.changePasswordResult = null;
      state.changePasswordError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateUser.pending, (state) => {
        state.updateUserLoading = true;
        state.updateUserResult = null;
        state.updateUserError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserResult = action.payload;
        state.updateUserError = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserResult = null;
        state.updateUserError = action.payload;
      })

      .addCase(updateUserCompanyLogo.pending, (state) => {
        state.updateUserCompanyLogoLoading = true;
        state.updateUserCompanyLogoResponce = null;
        state.updateUserCompanyLogoError = null;
      })
      .addCase(updateUserCompanyLogo.fulfilled, (state, action) => {
        state.updateUserCompanyLogoLoading = false;
        state.updateUserCompanyLogoResponce = action.payload;
        state.updateUserCompanyLogoError = null;
      })
      .addCase(updateUserCompanyLogo.rejected, (state, action) => {
        state.updateUserCompanyLogoLoading = false;
        state.updateUserCompanyLogoResponce = null;
        state.updateUserCompanyLogoError = action.payload;
      })

      .addCase(updateUserLanguage.pending, (state) => {
        state.updateUserLanguageLoading = true;
        state.updateUserLanguageResult = null;
        state.updateUserLanguageError = null;
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.updateUserLanguageLoading = false;
        state.updateUserLanguageResult = action.payload;
        state.updateUserLanguageError = null;
      })
      .addCase(updateUserLanguage.rejected, (state, action) => {
        state.updateUserLanguageLoading = false;
        state.updateUserLanguageResult = null;
        state.updateUserLanguageError = action.payload;
      })

      .addCase(verify.pending, (state) => {
        state.verifyLoading = true;
        state.verifyResult = null;
        state.verifyError = null;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = action.payload;
        state.verifyError = null;
      })
      .addCase(verify.rejected, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = null;
        state.verifyError = action.payload;
      })

      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true;
        state.changePasswordResult = null;
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordResult = action.payload;
        state.changePasswordError = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordResult = null;
        state.changePasswordError = action.payload;
      });
  },
});

export const {
  resetUpdateUser,
  resetUpdateUserCompanyLogo,
  resetUpdateUserLanguage,
  resetVerify,
  resetChangePassword,
} = userSlice.actions;
export default userSlice.reducer;
