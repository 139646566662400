import React, { Fragment } from "react";
import "./TrackingDistanceData.scss";

import { useTranslation } from "react-i18next";

const TrackingDistanceData = ({ order }) => {
  const { t } = useTranslation();

  const progress = order?.last_shipment_status_update?.distance_covered_percent || 0;
  const updateDate = order?.last_shipment_status_update?.created_at || null;
  const estDelivery = order?.last_shipment_status_update?.est || null;
  const etaDelivery = order?.last_shipment_status_update?.eta || null;
  const etdDelivery = order?.last_shipment_status_update?.etd || null;

  return (
    <Fragment>
      {order?.last_shipment_status_update && (
        <div id='ShipperTrackingSectionDistance'>
          <div className='content'>
            <h3>{t("shipper.forms.content.Distance covered")}</h3>
            <div>
              {estDelivery && (
                <h4>
                  {t("shipper.forms.content.Estimated delivery")}: <span>{estDelivery || "-"}</span>
                </h4>
              )}
              {etaDelivery && (
                <h4>
                  {t("shipper.forms.content.ETA delivery")}: <span>{etaDelivery || "-"}</span>
                </h4>
              )}
              {etdDelivery && (
                <h4>
                  {t("shipper.forms.content.ETD delivery")}: <span>{etdDelivery || "-"}</span>
                </h4>
              )}
              {updateDate && (
                <h5>
                  {t("shipper.forms.content.Update")}: <span>{updateDate || "-"}</span>
                </h5>
              )}
            </div>
          </div>
          <div className='progressContainer'>
            <div
              className='color'
              style={{
                width: parseFloat(progress) > 100 ? "100%" : `${parseFloat(progress)}%`,
              }}
            >
              <div className='dot'></div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TrackingDistanceData;
